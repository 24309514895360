const Translation = {
  nav: {
    dashboard: "仪表盘",
    dataset: "数据集",
    table: "基础数据",
    settings: "设置",
    workspace_manage: "成员管理",
    user_manage: "平台用户管理",
    notification: "系统通知",
    system_manage: "平台管理",
    official_website: "官方网站",
    change_language: "语言切换",
    tip: {
      network_available: "网络正常",
      network_unavailable: "网络连接异常，请检查网络",
    },
  },
  share: {
    admin: "可以管理",
    view: "只可查看",
  },
  sheet: {
    set_row_height: "设置行高",
    default_height: "默认行高",
    middle_height: "中等",
    high_height: "高",
    extremely_high_height: "极高",
    row_height: "行高",
    boolean_error:
      "无法识别为布尔值。请使用以下选项之一：true/false、yes/no、1/0、是/否 或 真/假。",
    date_error:
      "无法识别为日期。请使用 yyyy-MM-dd 或 yyyy-MM-dd HH:mm:ss 格式。",
    add_row: "新增一行",
    exceed_max_row:
      "当前表最多只显示 1 万条数据，超过1万条请下载 Excel 文件查看所有数据",
    decimal_format: "设置小数点",
  },
  table_filter: {
    add_filters: "添加筛选条件",
    count_filters: "{{count}}个筛选条件",
    filter: "筛选",
    title: "设置筛选",
    base_table_info:
      "使用筛选，通过条件配置快速过滤多余数据，从而获取更加精准的数据；基数数据表的源数据产生变化后，筛选数据结果将实时同步为最新数据。",
    when: "当",
    and: "并且",
    or: "或者",
    equal: "等于",
    unequal: "不等于",
    include: "包含",
    except: "不包含",
    null: "为空",
    not_null: "不为空",
    duplicate: "有重复",
    later_than: "晚于",
    later_than_or_equal_to: "晚于等于",
    earlier_than: "早于",
    earlier_than_or_equal_to: "早于等于",
    greater_than: "大于",
    greater_than_or_equal_to: "大于等于",
    less_than: "小于",
    less_than_or_equal_to: "小于等于",
    yes: "是",
    no: "否",
    add_filter: "添加筛选条件",
  },
  dataset: {
    title: "数据集",
    name: "数据集名称",
    version: "版本",
    versions: "版本记录",
    tables: "表",
    info: "基本信息",
    blank_table: "空白表",
    manage_tasks: "分配任务管理",
    add_tasks_in_batches: "批量分配任务",
    operator: "操作人",
    file_compare: "文件对比",
    select_compare_table_name: "选择对比表名",
    file_compare_tip: "不支持基础数据表的对比",
    data_setup: "设置数据",
    file_compare_settings: {
      only_changed: "只显示变化数据",
      only_greater_than_0: "只显示大于0数据",
      show_diff_value: "显示差异量",
      show_diff_ratio: "显示差异比例",
    },
    table_views: {
      select_table: "请选择基础数据表",
      select_table_field: "请选择表格/字段",
    },
    version_info: {
      version: "版本",
      name: "版本名称",
      description: "版本描述",
      copy: "复制上个版本数据",
      name_existed: "同一数据集下的版本名不能重复，请重新输",
      primary_table: "基础表",
      add_filters: "添加筛选条件",
      count_filters: "{{count}}个筛选条件",
      tip: "数据集支持多版本管理，可以查看历史版本以及版本间数据对比",
      get_settings: "获取设置",
      table_name: "选择复制表名",
      copy_table_tip:
        "温馨提示：系统自动记录上一次保存设置，当用户再次进行复制操作时，系统会自动应用上一次的设置",
    },
    template_import: {
      title: "从模版导入",
      all_tables: "所有表",
      base_tables: "基础数据表",
      base_table: "基础数据",
      view: "预览",
      use: "使用",
      create: "从模版创建",
      new_base_table: "创建基础数据表",
      link_base_table: "关联已存在的表",
      link_field: "关联字段",
      table_name_unique_fail: "同名基础数据表已存在",
    },
    task_status: {
      locked: "已锁定",
      unlocked: "未锁定",
      saved: "已保存",
      pending: "未开始",
      finished: "已完成",
    },
    action_status: {
      title: "执行进度",
      failed: "已失败",
      succeeded: "已成功",
      running: "运行中",
      pending: "未开始",
    },
    action_config: {
      title: "执行设置",
      pipeline: "执行流程",
      is_pipeline_blank: "请先选择执行流程",
    },
    action_record: {
      title: "日志",
      push_record: "执行日志",
      action_record: "操作日志",
      version: "版本",
      start_time: "操作时间",
      end_time: "结束时间",
      operator: "操作人",
      status: "状态",
      action: "操作",
      detail: "操作内容",
      actions: {
        LOCK_TASK: "表锁定",
        UNLOCK_TASK: "表解锁",
        LOCK_VERSION: "数据集锁定",
        UNLOCK_VERSION: "数据集解锁",
        CHANGE_DATA: "数据修改",
      },
      detail_info: {
        lock_task: "锁定了【{{table}}({{user}})】",
        unlock_task: "解锁了【{{table}}({{user}})】",
        lock_version: "锁定了数据集",
        unlock_version: "解锁了数据集",
        change_data: {
          delete: "删除：删除了【{{table}}({{user}})】的 {{count}} 条记录",
          add: "新增：新增了 {{count}} 行【{{table}}({{user}})】的数据",
          change: "修改：修改了【{{table}}({{user}})】的记录",
          change_cell: "【{{field}}】 由“{{origin}}”修改为“{{new}}”",
        },
      },
      logs: {
        view: "查看日志",
        title: "日志",
        main_log: "主日志",
        node_logs: "节点日志",
        name: "日志名称",
        id: "日志 ID",
        status: "状态",
        started_at: "开始时间",
        finished_at: "结束时间",
        duration: "时长",
        log: "日志",
        get_info_fail: "获取信息失败",
      },
    },
    push_config: {
      title: "执行设置",
      push_method: "推送方式",
      odp_api_token: "ODP应用授权Token",
      workspace_id: "Flow目标空间ID",
      workspace_id_tip:
        "打开小流智算应用平台-工作空间设置-空间授权管理，复制空间ID",
      flow_api_token_tip:
        "打开小流智算应用平台-工作空间设置-空间授权管理，复制令牌状态有效的令牌",
      app: "应用名称",
      is_push_method_blank: "请先选择推送方式",
      is_blank: "请先填写Flow目标空间ID和Flow API Token",
      folder_is_blank: "请先填写Flow目标空间ID，应用名称和Flow API Token",
      session: "Flow目标文件夹",
      auto_lock: "自动锁定文件",
      auto_solve: "自动算法求解",
      flow_api_token_invalid: "Flow API Token 无效，请检查是否过期",
    },
    progress: {
      version_progress: "进度管理",
      table: "表名",
      assignee: "执行人",
      is_locked: "是否锁定",
      title: "进度",
    },
    actions: {
      push: "推送",
      execute: "执行",
      lock: "锁定",
      unlock: "解锁",
      add_version: "新增版本",
      update_version: "修改版本",
      data_validation: "数据验证",
      add_table: "新增表",
      tasks: "分配任务",
      share: "分享数据集",
      compare: "对比",
      balance: "配平",
      succeeded_notification: "执行成功",
      failed_notification: "执行失败",
      batch_operations: "批量分配",
      batch_import: "批量导入",
      delete_table: "删除表",
    },
    tips: {
      select_user: "请选择成员",
      confirm_copy: "确认复制数据集吗？",
      confirm_copy_desc: "仅复制当前结构，确定复制？",
      confirm_delete: "确认删除数据集吗？",
      confirm_delete_desc: `<p>已选择数据集: {{dataset_name}}</p>
      <p>删除后将无法恢复，请谨慎操作</p>`,
      need_lock_dataset: "需先锁定数据集",
      need_push_config: "请先配置设置项",
      table_is_locked: "当前表已锁定，如需解锁请找管理员或者创建者",
      need_unlock: "如需操作，请先解锁数据集",
      confirm_task_lock: "确认保存并锁定当前任务数据？",
      confirm_task_lock_desc: "一旦锁定，仅管理员可解锁",
      table_drag_tip: "拖拽可调整顺序，拖拽成功后立即生效",
      user_existed: "当前操作人已新增",
      only_view: "只可查看该数据集内容",
      can_manage: "已拥有管理该数据集的所有操作权限",
      cannot_lock: "有子任务未锁定，请审查",
      flow_app_disabled: "Flow 中未启用该应用",
      flow_app_not_set: "Flow 应用名称为空，请在设置中重新选择",
      api_token_not_set: "ODP 应用授权 Token 未设置，请在设置中进行配置",
      greater_than_production: "限制值大于生产计划",
      greater_than_demand: "限制值大于需求计划",
      balanced_production_demand_title: "提示",
      balanced_production_demand_content:
        "需求计划与生产计划数据未配平，是否进行自动配平？",
      select_compare_version: "选择对比版本",
      select_origin_version: "请选择原版本",
      select_new_version: "请选择对比版本",
      select_diff_version: "请选择不同版本进行对比",
      select_file: "请选择文件",
      data_setup_mismatch: "数据表字段不匹配",
      data_setup: "设置数据",
      data_setup_tips:
        "分配任务时可选择需要分配的基础数据，同时可以添加筛选条件对数据进行二次筛选",
      setup_flow_app: "请先设置Flow 应用",
      no_data: "当前版本无数据，请检查",
      setup_pipeline: "请先设置执行流程",
      data_validation_failed: "数据检查失败",
      missing_table: "缺少【{{table}}】表，请补充，请检查表名是否正确",
      missing_field: "第{{row}}行【{{field}}】字段值为空",
      invalid_reference:
        "【{{reference_table}}】第{{row}}行的{{reference_column}}【{{value}}】在【{{definition_table}}】表中不存在，请检查",
      task_assigned: "已分配",
      task_assigne_table_required: "请选择需要分配的表",
      task_assigne_operator_required: "请选择需要分配的操作人",
      add_tasks_in_batches_success: "批量分配任务成功",
      add_tasks_in_batches_fail:
        "批量分配任务: 成功{{success_count}} 失败{{fail_count}}",
      confirm_delete_version: "确认删除该数据集版本吗？",
      confirm_delete_table: "确认删除此表吗？一经删除无法找回。",
    },
    sheet_error_tips: {
      title: "报错提示",
      jump_to: "跳转至",
      message: "第{{row}}行, {{error}}",
    },
  },
  table: {
    title: "基础数据",
    table: "表",
    name: "表名称",
    identifier: "标识名",
    members: "当前成员",
    info: "基本信息",
    import: {
      upload_blank: "请上传文件",
      table_blank: "请选择表格",
      field_blank: "请选择字段",
      match_blank: "请已选择的字段进行匹配",
      upload: "上传文件",
      upload_format: "支持 .csv, .xls, .xlsx 格式",
      mult_table: "您上传的文件有多个表格，请选择",
      target_field: "目标字段",
      source_field: "源字段",
      title: "导入数据",
      match_field: "匹配字段",
      batch_import: "批量导入数据",
      select_tables: "选择表格",
      no_task_tips: "请先将任务分配给当前用户并且未锁定",
      import_and_save: "导入并保存",
      import_and_lock: "导入并锁定",
      import_failed: "{{table}}表{{action}}失败",
    },
    field: {
      title: "表头",
      name: "字段名",
      identifier: "字段标识名",
      type: "字段类型",
      reference_table: "引用表",
      reference_field: "引用字段",
      reference_field_display: "引用字段显示值",
      is_pk: "是否主键",
      is_unique: "是否唯一",
      is_nullable: "是否允许空值",
      add_field: "新增表头",
      edit_field: "编辑表头",
      name_existed: "字段名已存在，请重新输入",
      name_format: "字段名仅支持中英文和常见字符",
      identifier_info:
        "字段标识名将作为数据库中存储使用的标识，仅支持小写字母、下划线、数字以及美元符号（$），且必须以小写字母开头",
      identifier_format:
        "仅支持小写字母、下划线、数字以及美元符号（$），且必须以小写字母开头",
      identifier_existed: "标识名已存在，请重新输入",
      text: "文本",
      number: "数字",
      date: "日期",
      boolean: "布尔值",
      reference: "引用",
      rule: "请输入验证规则",
      min: "最小值",
      max: "最大值",
      only_unique: "只显示值为唯一的字段",
      readonly: "只读",
    },
    actions: {
      info: "表详情",
      data_validation: "数据验证",
      share: "分享基础数据",
      remove_member: "移除成员",
      edit: "表编辑",
      new: "新建表",
      sync: "接口数据",
    },
    action_record: {
      title: "日志",
      action_record: "操作日志",
      start_time: "操作时间",
      end_time: "结束时间",
      operator: "操作人",
      status: "状态",
      action: "操作",
      detail: "操作内容",
      actions: {
        CHANGE_DATA: "数据修改",
        SYNC_DATA: "接口同步",
      },
      detail_info: {
        change_data: {
          delete: "删除：删除了 {{count}} 条记录",
          add: "新增：新增了 {{count}} 行数据",
          change_cell: "修改：【{{field}}】 由“{{origin}}”修改为“{{new}}”",
        },
        sync_data: {
          sync: "【{{datetime}}】更新了接口数据",
        },
      },
    },
    tips: {
      select_user: "请选择成员",
      only_view: "只可查看该基础数据内容",
      can_manage: "已拥有管理该表格的所有操作权限",
      confirm_copy: "确认复制表吗？",
      confirm_copy_desc: "只复制当前表结构，确认复制？",
      confirm_delete: "确认删除表吗？",
      confirm_delete_desc: `<p>已选择表: {{table_name}}</p>
      <p>删除后将无法恢复，请谨慎操作</p>`,
      delete_fail: "无法删除表格，请稍后重试",
      delete_success: "删除成功",
      is_referenced: "当前表字段已被{{content}}引用，不允许删除",
      has_views: "当前表已被数据集{{content}}引用，不允许删除",
      change_field_fail: "表格不为空，无法修改表头",
      name_existed: "表名称已存在，请重新输入",
      fields_empty: "表头空，请重新输入",
      no_permission: "无权限修改「创建人」的权限",
      name_format: "表名仅支持中英文和常见字符",
      sync_data: "从外部同步数据",
      sync_data_help:
        "用户可选择通过接口对接方式获得数据，当选中后数据不可人工修改",
      pk_must: "请创建主键",
      field_readonly: "仅管理员可修改",
      syncing: "数据同步中, 请稍后",
      add_field: "请新增表头",
    },
  },
  workspace: {
    title: "空间设置",
    name: "空间名称",
    role: "角色",
    member: "成员",
    api_key: "授权",
    token: "应用授权token",
    admin: "管理员",
    owner: "所有者",
    email: "邮箱",
    account: "账户",
    username: "用户名",
    created_at: "创建时间",
    created_by_name: "邀请人",
    logined: {
      title: "激活状态",
      active: "已激活",
      inactive: "未激活",
    },
    last_used: "最后使用时间",
    upload_file_method: "将文件拖拽到这里或者点击此区域开始导入",
    upload_file_format: "支持 .csv, .xls, .xlsx 格式",
    import_rule: `<p>导入规则</p>
    <p>1.限{{size}}M以内；</p>
    <p>2.单次最多导入{{num}}条，超出部分将无法自动导入；</p>
    <p>3.点击下载模板 <a>成员模板.xlsx</a></p>`,
    import_result:
      "成功导入<success>{{success}}</success>条数据，未导入<failure>{{failure}}</failure>条数据。 <a>下载导入失败数据</a>",
    actions: {
      add: "新增空间",
      invite_member: "成员邀请",
      invite: "邀请",
      invite_button: "邀请{{num}}人",
      batch_add: "批量导入",
      update_member: "成员修改",
      delete_member: "删除成员",
      reupload: "重新上传",
      continue_invite: "继续邀请",
      edit: "编辑",
      quit: "退出空间",
      confirm_quit: "确认退出",
      membership_management: "成员管理",
      auth_management: "授权管理",
    },
    tips: {
      workspace_not_found: "当前空间不存在，请刷新页面",
      no_workspace: "未选择工作空间",
      no_memeber: "未选择成员",
      member_is_existed: "该用户已在工作空间中",
      confirm_delete_member: "确认要删除成员 {{name}} 吗？",
      confirm_api_key: "确认要删除应用授权token {{name}} 吗？",
      cannot_quit: "工作空间所有者无法直接退出空间",
      save_key: "请小心保存并确保不被泄露。一旦关闭，您将无法再次从系统中获取",
      you_key: "您的应用授权token是：",
      copy_token: "复制token",
      change_fail: "切换空间失败",
      invitation_length: "最多同时新增{{num}}条记录",
      no_user_add: "未添加用户",
      no_upload_file: "没有上传的文件",
      is_not_excel: "{{name}}不是excel文件",
      max_excel_size: "excel不能超过{{size}}M",
      max_excel_records: "excel不能超过{{num}}行",
      invalid_excel: "excel必须有账户和角色列",
      invalid_email: "这不是一个正确的格式，请重新输入",
      confirm_quit_content: `<p>已选择空间： <b>{{workspace}}</b></p>
      <p>退出后，你将无法进入当前空间，请谨慎操作</p>`,
      existed: "已加入",
      copy_workspace_id: "复制空间ID{{status}}",
    },
  },
  notification: {
    popover: {
      dataset: "数据集",
      workspace: "空间",
      platform: "平台",
      read_all: "全部已读",
    },
    dataset: "数据集通知",
    workspace: "空间通知",
    system: "平台通知",
    all: "全部",
    read: "已读",
    unread: "未读",
  },
  common: {
    version: "版本",
    all: "全部",
    workspace: "空间",
    success: "成功",
    fail: "失败",
    logout: "退出登录",
    actions: "操作",
    edit: "编辑",
    delete: "删除",
    add: "新增",
    update: "更新",
    create: "创建",
    info: "详情",
    copy: "复制",
    close: "关闭",
    ok: "确定",
    add_with_info: "新增{{title}}",
    manage_with_info: "{{title}}管理",
    status: "状态",
    enabled: "已启用",
    created_at: "创建时间",
    updated_at: "更新时间",
    description: "描述",
    cancel: "取消",
    save: "保存",
    required: "请输入{{title}}",
    invalid: "无效的{{title}}",
    result_status: "{{title}}{{status}}",
    name: "名称",
    owner: "创建人",
    char_len_limit: "{{title}}最多不能超过{{count}}个字",
    try_again: "请稍后重试",
    import: "导入",
    export: "导出",
    no_permission: "暂无权限",
    back: "返回",
    share: "分享",
    change: "修改",
    first: "最顶",
    previous: "上一个",
    next: "下一个",
    last: "最底",
    check_all: "全选",
    yes: "是",
    no: "否",
    form_mode: "表单",
    step: {
      previous: "上一步",
      next: "下一步",
    },
    finish: "完成",
    table: "数据表",
    source: "数据源",
    data: "数据",
    reset: "重置",
    search: "查询",
    not_configured: "未设置",
    total: "共 {{count}} 条",
    old: "旧",
    remove: "移除",
    server_error_tips: "请稍后尝试或者联系客服人员",
    refresh: "刷新",
  },
  flow_instance: {
    get_instances_title: "获取流量流向算法实例",
    select_instances_title: "选择实例",
    instance_id: "实例ID",
    instance_name: "实例名称",
    instance_description: "描述",
  },
  chart_view: {
    title: "图表",
    name: "名称",
    select_dataframe: "选择表",
    select_chart_type: "选择图表类型",
    count_records_label: "记录总数",
    count_records: "统计记录总数",
    count_a_field: "统计指定字段",
    select_filed: "请选择统计指定字段",
    sum: "求和",
    min: "最小值",
    max: "最大值",
    average: "平均值",
    empty_stacked_by: "[无]",
    sort_by_x: "按 X 轴的值排序",
    sort_by_y: "按 Y 轴的值排序",
    sort_rule_ascending: "按正序排序",
    sort_rule_descending: "按倒序排序",
    more_settings: "更多配置",
    color_picker: "选择配色方案",
    multi_color_theme: "多色主题",
    monochrome_gradient_theme: "单色渐变主题",
    theme_color_1: "配色1",
    theme_color_2: "配色2",
    theme_color_3: "配色3",
    theme_color_4: "配色4",
    theme_color_5: "配色5",
    theme_color_6: "配色6",
    theme_color_7: "配色7",
    theme_color_8: "配色8",
    chart_type_enum: {
      heatmap: "热力图",
      map: "地图",
      location_relationship: "地理位置关系图",
      column_chart: "柱状图",
      stacked_column_chart: "堆叠柱状图",
      percentage_column_chart: "百分比柱状图",
      bar_chart: "条形图",
      stacked_bar_chart: "堆叠条形图",
      percentage_bar_chart: "百分比条形图",
      pie_chart: "饼状图",
      donut_chart: "环状图",
      line_chart: "折线图",
      stacked_line_chart: "堆叠折线图",
      scatter_plot: "散点图",
      funnel_chart: "漏斗图",
    },
    config: {
      pie_chart: {
        value: "数值",
        dimension: "维度",
        text_size: "文本大小",
      },
      donut_chart: {
        value: "数值",
        dimension: "维度",
        text_size: "文本大小",
      },
      bar_chart: {
        dimension: "维度（X 轴）",
        value: "数值（Y 轴）",
        stacked_by: "按下方字段分组",
        sort_by: "选择一个坐标轴排序",
        sort_rule: "设置排序规则",
        text_size: "文本大小",
      },
      stacked_bar_chart: {
        dimension: "维度（X 轴）",
        value: "数值（Y 轴）",
        stacked_by: "按下方字段堆叠",
        sort_by: "选择一个坐标轴排序",
        sort_rule: "设置排序规则",
        text_size: "文本大小",
      },
      percentage_bar_chart: {
        dimension: "维度（X 轴）",
        value: "数值（Y 轴）",
        stacked_by: "按下方字段百分比堆叠",
        sort_by: "选择一个坐标轴排序",
        sort_rule: "设置排序规则",
        text_size: "文本大小",
      },
      line_chart: {
        dimension: "维度（X 轴）",
        value: "数值（Y 轴）",
        stacked_by: "按下方字段分组",
        sort_by: "选择一个坐标轴排序",
        sort_rule: "设置排序规则",
        text_size: "文本大小",
      },
      stacked_line_chart: {
        dimension: "维度（X 轴）",
        value: "数值（Y 轴）",
        stacked_by: "按下方字段堆叠",
        sort_by: "选择一个坐标轴排序",
        sort_rule: "设置排序规则",
        text_size: "文本大小",
      },
      column_chart: {
        dimension: "维度（X 轴）",
        value: "数值（Y 轴）",
        stacked_by: "按下方字段分组",
        sort_by: "选择一个坐标轴排序",
        sort_rule: "设置排序规则",
        text_size: "文本大小",
      },
      stacked_column_chart: {
        dimension: "维度（X 轴）",
        value: "数值（Y 轴）",
        stacked_by: "按下方字段堆叠",
        sort_by: "选择一个坐标轴排序",
        sort_rule: "设置排序规则",
        text_size: "文本大小",
      },
      percentage_column_chart: {
        dimension: "维度（X 轴）",
        value: "数值（Y 轴）",
        stacked_by: "按下方字段百分比堆叠",
        sort_by: "选择一个坐标轴排序",
        sort_rule: "设置排序规则",
        text_size: "文本大小",
      },
      gaode_heatmap: {
        opacity: "整体透明度",
        show_tip: "是否显示热力值标注",
        unit: "热力的单位",
        radius: "热力的聚合半径",
        colors: "配色方案",
        max: "热力值的最大值",
        min: "热力值的最小值",
        difference: "是否关闭颜色过渡",
        is3d: "是否开启3d效果",
        px: "像素",
        meter: "地理真实的距离(米)",
        latitude: "纬度字段",
        longitude: "经度字段",
        value: "值字段",
      },
      location_relationship: {
        opacity: "整体透明度",
        show_tip: "是否显示名称标注",
        main_latitude: "主点纬度字段",
        main_longitude: "主点经度字段",
        sub_latitude: "子点纬度字段",
        sub_longitude: "子点经度字段",
        main_name: "主点名称字段",
        sub_name: "子点名称字段",
        main_size: "主点尺寸",
        sub_size: "子点尺寸",
        line_width: "线宽",
      },
      map_type: {
        title: "请选择需要使用的地图",
        default: "默认地图",
        gaode: "高德地图",
        baidu: "百度地图",
        input_key: "填写应用的Key",
      },
    },
    action: {
      add: "新增图表",
    },
    tip: {
      create: "创建图表{{status}}",
      update: "更新图表{{status}}",
      delete: "删除图表{{status}}",
      table_blank: "请选择表",
    },
    confirm: {
      delete: "是否确定删除图表?",
    },
  },
  system_manage: {
    pipeline_manage: {
      pipeline: "执行流程",
      manifest: "执行流程配置",
      active: "状态",
      active_true: "启用",
      active_false: "停用",
      is_private: "类型",
      public: "全局",
      private: "私有",
      private_workspaces: "分配空间",
      confirm_delete: "是否确定删除 {{pipeline_name}} ?",
      tip: {
        add: "新增执行流程{{status}}",
        update: "更新执行流程{{status}}",
        id_exist: "id已存在",
        name_exist: "name已存在",
        invalid_id: "id不存在",
        cannot_edit_id: "id不可修改",
        cannot_edit_name: "name不可修改",
      },
    },
    base_table_manage: {
      base_table: "基础数据",
      confirm_delete: "是否确定删除 {{table_name}} ?",
    },
  },
  dashboard: {
    title: "仪表盘",
    add_chart: "添加图表",
    full_screen: "全屏显示",
    remove: "从仪表盘移除",
    origin: "来源：「{{dataset_name}}」",
    add: "添加",
    tips: {
      remove: "移除后将不在仪表盘页面显示，确定要移除吗？",
    },
  },
};

export default Translation;
